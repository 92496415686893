<template>
  <modal-wrapper
    :id="id"
    size="md"
  >
    <template #title>
      Informazioni
    </template>
    <template #body>
      <p>
        Al fine di una ricerca ottimale, si consiglia di inserire un numero di caratteri il più vicino possibile
        al risultato desiderato.
      </p>
      <p>
        La ricerca utilizza sistemi di terze parti, ed anche in caso di errore o mancanza di risultato
        non sarà possibile riaddebitare i fondi spesi.
      </p>
    </template>
    <template #footer>
      <b-button
        variant="danger"
        size="sm"
        @click="closeModal"
        class="ms-auto"
      >
        Annulla
      </b-button>
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ProfileSearchDataSearchModalInfo',
  components: {
    ModalWrapper,
  },
  props: {
    id: String,
  },
  computed: {},
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style scoped>

</style>
